import React from "react";
import RDCircleImage from "../../assets/images/Group 39162.png";

import "../Login/Login.css";
import { Box } from "@mui/material";

const StandardLeftSideOfRD = () => {
  return (
    <div className="login-Resumes_Image" style={{ width: "100%", position: "relative" }}>
      <Box sx={{ width: "100%" }}>
        <img src={"https://ccicons.s3.us-east-1.amazonaws.com/Resumes.webp"} alt="Resumes.Digital" style={{ width: "100%", height: "100vh" }} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img src={RDCircleImage} alt="Resumes.Digital" style={{ width: "300px", height: "auto" }} />
      </Box>
    </div>
  );
};

export default StandardLeftSideOfRD;
