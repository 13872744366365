import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import "../Login/Login.css";
import StandardLeftSideOfRD from "../StandardLeftSideOfRD/StandardLeftSideOfRD";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Mobile = useMediaQuery("(max-width:800px)");

  useEffect(() => {
    const handleNavigation = () => {
      const token = Cookies.get("token");

      if (token) {
        navigate("/UpcomingBooking");
      }
    };
    handleNavigation();
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.type === "Login" && window.location.hostname === "mentor.resumes.digital") {
        navigate("/UpcomingBooking");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <ToastContainer position="top-center" />
      <div
        className="login-Container"
        style={{
          display: "flex",
          height: "100vh",
        }}
      >
        {!Mobile && (
          <div className="login-Resumes_Image" style={{ width: "100%" }}>
            <StandardLeftSideOfRD />}
          </div>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <iframe src="https://staging-feature.resumes.digital/Login" width="100%" height="500px" style={{ border: "none" }} title="Embedded Website" allow="camera; microphone; fullscreen; geolocation; clipboard-read; clipboard-write; autoplay; encrypted-media" />
        </Box>
        {/* <Alert
            show={changeTemplate}
            title="Alert"
            body="You are not a registered user with us. Please sign using Google/Apple on the CareerCarve App to continue."
            yesButton="Signup"
            alertResponse={alertResponse}
            onHide={() => setChangeTemplate(false)}
          /> */}
      </div>
    </>
  );
};

export default Login;
