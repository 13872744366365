import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
// import Login from './components/Login/Login';
import UpcomingBooking from "./components/UpcomingBooking/UpcomingBooking";
import Login from "./components/Login/Login";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/UpcomingBooking" exact element={<UpcomingBooking />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
