import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const UpcomingBooking = () => {
  const iFRameRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.type === "LOGOUT") {
        navigate("/");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/");
    }
  }, []);
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe ref={iFRameRef} id="myIframe" src={`${process.env.REACT_APP_MENTOR_ACTIVITY_URL}/UpcomingBooking`} width="100%" height="100%" style={{ border: "none" }} title="Embedded Website" allow="camera; microphone; fullscreen; geolocation; clipboard-read; clipboard-write; autoplay; encrypted-media" />
    </div>
  );
};

export default UpcomingBooking;
